export default [
    {
        market_code: "ar_OM",
        market_name: "OM (ar)"
    },
    {
        market_code: "bg_BG",
        market_name: "BG (bg)"
    },
    {
        market_code: "da_DK",
        market_name: "DK (da)"
    },
    {
        market_code: "de_AT",
        market_name: "AT (de)"
    },
    {
        market_code: "de_CH",
        market_name: "CH (de)"
    },
    {
        market_code: "de_DE",
        market_name: "DE (de)"
    },
    {
        market_code: "de_IT",
        market_name: "IT (de)"
    },
    {
        market_code: "el_GR",
        market_name: "GR (el)"
    },
    {
        market_code: "en_AE",
        market_name: "AE (en)"
    },
    {
        market_code: "en_BD",
        market_name: "BD (en)"
    },
    {
        market_code: "en_BE",
        market_name: "BE (en)"
    },
    {
        market_code: "en_BH",
        market_name: "BH (en)"
    },
    {
        market_code: "en_CA",
        market_name: "CA (en)"
    },
    {
        market_code: "en_DO",
        market_name: "DO (en)"
    },
    {
        market_code: "en_DU",
        market_name: "DU (en)"
    },
    {
        market_code: "en_EE",
        market_name: "EE (en)"
    },
    {
        market_code: "en_FI",
        market_name: "FI (en)"
    },
    {
        market_code: "en_GB",
        market_name: "GB (en)"
    },
    {
        market_code: "en_IE",
        market_name: "IE (en)"
    },
    {
        market_code: "en_IL",
        market_name: "IL (en)"
    },
    {
        market_code: "en_KR",
        market_name: "KR (en)"
    },
    {
        market_code: "en_KW",
        market_name: "KW (en)"
    },
    {
        market_code: "en_KZ",
        market_name: "KZ (en)"
    },
    {
        market_code: "en_ID",
        market_name: "ID (en)"
    },
    {
        market_code: "en_LK",
        market_name: "LK (en)"
    },
    {
        market_code: "en_LT",
        market_name: "LT (en)"
    },
    {
        market_code: "en_LV",
        market_name: "LV (en)"
    },
    {
        market_code: "en_NL",
        market_name: "NL (en)"
    },
    {
        market_code: "en_NO",
        market_name: "NO (en)"
    },
    {
        market_code: "en_OM",
        market_name: "OM (en)"
    },
    {
        market_code: "en_PH",
        market_name: "PH (en)"
    },
    {
        market_code: "en_PL",
        market_name: "PL (en)"
    },
    {
        market_code: "en_RO",
        market_name: "RO (en)"
    },
    {
        market_code: "en_SL",
        market_name: "SL (en)"
    },
    {
        market_code: "en_US",
        market_name: "US (en)"
    },
    {
        market_code: "en_XH",
        market_name: "XH (en)"
    },
    {
        market_code: "es_CL",
        market_name: "CL (es)"
    },
    {
        market_code: "es_CR",
        market_name: "CR (es)"
    },
    {
        market_code: "es_DO",
        market_name: "DO (es)"
    },
    {
        market_code: "es_ES",
        market_name: "ES (es)"
    },
    {
        market_code: "es_MX",
        market_name: "MX (es)"
    },
    {
        market_code: "es_PA",
        market_name: "PA (es)"
    },
    {
        market_code: "es_PY",
        market_name: "PY (es)"
    },
    {
        market_code: "et_EE",
        market_name: "EE (et)"
    },
    {
        market_code: "fi_FI",
        market_name: "FI (fi)"
    },
    {
        market_code: "fr_CH",
        market_name: "CH (fr)"
    },
    {
        market_code: "fr_FR",
        market_name: "FR (fr)"
    },
    {
        market_code: "fr_MA",
        market_name: "MA (fr)"
    },
    {
        market_code: "he_IL",
        market_name: "IL (he)"
    },
    {
        market_code: "hr_HR",
        market_name: "HR (hr)"
    },
    {
        market_code: "hu_HU",
        market_name: "HU (hu)"
    },
    {
        market_code: "is_IS",
        market_name: "IS (is)"
    },
    {
        market_code: "it_CH",
        market_name: "CH (it)"
    },
    {
        market_code: "it_IT",
        market_name: "IT (it)"
    },
    {
        market_code: "ko_KR",
        market_name: "KR (ko)"
    },
    {
        market_code: "lt_LT",
        market_name: "LT (lt)"
    },
    {
        market_code: "lv_LV",
        market_name: "LV (lv)"
    },
    {
        market_code: "nl_NL",
        market_name: "NL (nl)"
    },
    {
        market_code: "no_NO",
        market_name: "NO (no)"
    },
    {
        market_code: "pl_PL",
        market_name: "PL (pl)"
    },
    {
        market_code: "ro_RO",
        market_name: "RO (ro)"
    },
    {
        market_code: "ru_KZ",
        market_name: "KZ (ru)"
    },
    {
        market_code: "sl_SL",
        market_name: "SL (sl)"
    },
    {
        market_code: "sr_RS",
        market_name: "RS (sr)"
    },
    {
        market_code: "sv_SE",
        market_name: "SE (sv)"
    },
    {
        market_code: "uk_UA",
        market_name: "UA (uk)"
    }
]
